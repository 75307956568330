<script lang="ts" setup>
import Day from '~/components/calendars/Day.vue'
import DayOfWeekShort from '~/components/calendars/DayOfWeekShort.vue'
import TimeOfDay from '~/components/calendars/TimeOfDay.vue'
import AuthScope from '~/enums/auth-scope'
import { useConsultationOrderStore } from '~/store/consultation-order'
import { useSpecialistIntervalsStore } from '~/store/specialist-intervals'

const props = defineProps<{
  specialistId: string
}>()

const activeDate = ref<string | null>(null)
const activeTime = ref<string | null>(null)

const orderStore = useConsultationOrderStore()
const specialistIntervalsStore = useSpecialistIntervalsStore()
const { getSpecialistIntervals } = storeToRefs(specialistIntervalsStore)

const getAvailableTimes = computed((): any[] => {
  if (!activeDate.value) {
    return []
  }

  return getSpecialistIntervals.value.find((x: any) => x.date === activeDate.value)?.timesOfDay ?? []
})

watch(() => activeDate.value && activeTime.value, () => {
  if (activeDate.value && activeTime.value) {
    orderStore.form.consultationData.startedAt = `${activeDate.value} ${activeTime.value}`
  }
})

onMounted(async () => await specialistIntervalsStore.fetchSpecialistIntervals(props.specialistId, AuthScope.Client))
</script>

<template>
  <div class="rounded-[20px] bg-main-light-gray p-4">
    <div class="flex items-center justify-between">
      <b class="text-lg font-medium">Дата и&nbsp;время записи</b>
      <SvgIcon class="!size-5" name="outlined/calendar" />
    </div>

    <div class="mt-2">
      <div class="-mr-2 flex items-center justify-between">
        <div
          v-for="(x, i) in getSpecialistIntervals"
          :key="i"
          class="mr-2 flex basis-full flex-col items-center"
        >
          <DayOfWeekShort :day-number="x.dayNumber" />

          <Day
            :date="x.date"
            :is-active="activeDate === x.date"
            :is-disabled="x.isUnavailable"
            @selected="activeDate = x.date"
          />
        </div>
      </div>

      <FreeGallery class="mb-1 mt-4">
        <TimeOfDay
          v-for="(x, i) in getAvailableTimes"
          :key="i"
          :is-active="activeTime === x.time"
          :is-disabled="x.isUnavailable"
          :time="x.time"
          @selected="activeTime = x.time"
        />
      </FreeGallery>
    </div>
  </div>
</template>
